import React, { Component } from 'react';
import Banner from './banner';
import UnderBannerRow from './under-banner-row';

import sideImage from './images/catalogs.jpg';

const website = 'HSE Congress 2018';

class Register extends Component {
	render() {
		return (
			<div>
				<Banner />
				<UnderBannerRow message='Register to attend' />
				<Form />
			</div>
		);
	}
}

export default Register;

class Form extends Component {
	constructor() {
		super();
		this.state = {
			name: '',
			company: '',
			position: '',
			email: '',
			mobile: '',
			landline: '',
			consent: true,
			fail: false,
			failSubmit: false,
			success: false
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(e) {
		const states = ['name', 'company', 'position', 'email', 'mobile'];

		this.setState({
			fail: false,
			failSubmit: false,
			success: false
		});

		e.preventDefault();

		let check = states.every(elm => {
			return this.state[elm];
		});

		if (!check) {
			return this.setState({fail: true});
		}

		fetch('/email/register', {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				website: website,
				name: this.state.name,
				company: this.state.company,
				position: this.state.position,
				email: this.state.email,
				mobile: this.state.mobile,
				landline: this.state.landline,
				consent: this.state.consent
			})
		}).then((res) => {
			this.setState({success: true});
			[...states, 'landline'].forEach(elm => {this.setState({[elm]: ''})});
		}).catch((err) => {
			console.log(err);
			this.setState({failSubmit: true});
		});
	}

	onChange(e) {
		this.setState({[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value});
	}

	render() {
		return (
			<div className='container'>
				<div className='row' style={{paddingTop: 30}}>
					<div className='col-xs-12 col-sm-6'>
						<span>(Our team will contact you to confirm attendance)</span>
						<br />
						<br />
						<form className='form-horizontal'>
							<input type='hidden' name='website' value={website} />
							{[
								{
									title: 'Name',
									name: 'name',
									value: this.state.name,
									required: true
								},
								{
									title: 'Company',
									name: 'company',
									value: this.state.company,
									required: true
								},
								{
									title: 'Position',
									name: 'position',
									value: this.state.position,
									required: true
								},
								{
									title: 'Email',
									name: 'email',
									value: this.state.email,
									required: true
								},
								{
									title: 'Mobile Phone',
									name: 'mobile',
									value: this.state.mobile,
									required: true
								},
								{
									title: 'Office Phone',
									name: 'landline',
									value: this.state.landline,
									required: false
								}
							].map(elm => {
								return <FormInput key={elm.name} title={elm.title} name={elm.name} value={elm.value} required={elm.required} onChange={this.onChange} />
							})}
							<div className="checkbox">
								<label>
									<input type="checkbox" name="consent" onChange={this.onChange} checked={this.state.consent}	/>
									I understand that by registering for this event I will be providing my data to the events organiser.I consent to this personal data being transferred and accept that other attendees as well as exhibitors or sponsors may contact me about their projects or services after the event. I agree that the attendee or sponsor may transfer my data outside of the European Economic Area for these purposes and I consent to such transfer. I also understand and agree that my personal data may be held and used by the organiser of this event, and any third party hosting provider acting on its behalf, in order to stage the event and to analyse visitor traffic with a view to improving the event experience for participants.
								</label>
							</div>
							<br />
							<div style={{textAlign: 'center'}}>
								{this.state.fail ? (<div className='alert alert-danger' style={{marginBottom: 5}}>Please complete all required fields!</div>) : ''}
								{this.state.failSubmit ? (<div className='alert alert-danger' style={{marginBottom: 5}}>Something went wrong! Please call us to register you.</div>) : ''}
								{this.state.success ? (<div className='alert alert-success' style={{marginBottom: 5}}>Thank you for registering!</div>) : ''}
								<button type='button' className='btn bg-color-yellow' style={style.button} onClick={this.onSubmit}>Register</button>
							</div>
						</form>
					</div>
					<div className='col-xs-12 col-sm-6'>
						<img src={sideImage} style={{width: '100%'}} alt='' />
					</div>
				</div>
			</div>
		)
	}
}

class FormInput extends Form {
	render() {
		let showRequired = this.props.required ? (<strong style={{color: 'red'}}>*</strong>) : '';

		return (
			<div className='form-group'>
				<div className='input-group' style={style.inputGroup}>
					<div className='input-group-addon bg-color-charcoal' style={style.inputGroupAddon}>{this.props.title} {showRequired}</div>
					<input
						className='form-control bg-color-grey'
						style={style.input}
						type='text'
						name={this.props.name}
						value={this.props.value}
						onChange={this.props.onChange}
					/>
				</div>
			</div>
		)
	}
}

const style = {
	inputGroup: {
		width: '100%',
		padding: '0px 10px'
	},
	inputGroupAddon: {
		width: '125px',
		borderColor: '#000',
		borderRadius: 0,
		color: '#fff',
		fontWeight: 'bold'
	},
	input: {
		color: '#fff',
		border: '0px',
		borderRadius: 0
	},
	button: {
		marginBottom: 30,
		fontWeight: 'bold',
		borderRadius: 0,
		border: 0
	}
}
