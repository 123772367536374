import React, { Component } from 'react';

import './css/short-description.css';

class ShortDescription extends Component {
	render() {
		return (
			<div className='container'>
				<div className='row short-description' style={{padding: '30px 0px 15px'}}>
					<div className='col-xs-12' style={{textAlign: 'justify'}}>
						<div className='player-wrapper'>
							<iframe src='https://player.vimeo.com/video/237887001' width='100%' height='100%' title='HSE Congress 2017' frameBorder='0' allowFullScreen></iframe>
						</div>
						<p>This year’s Health, Safety & Environmental Congress will bring together some of the UK’s leading HSE Professionals to showcase and discuss some of the key challenges across multiple industries in the UK.</p>
						<p>The highly engaging program across two days will piece together topics around Safety Culture, Behavioural Safety, Digital Transformation, Risk Management & Operational Effectiveness, Future Technologies and much more. Stricter regulations and the focus on reducing the number of incidents means HSE professionals need to continue to educate, thought-lead and keep up with the pace of change within their industry.</p>
						<p>The HSE congress will provide a content-based platform of Keynotes, Panels, Workshops and Roundtables, all built into two exciting days in the luxurious and breath-taking surroundings of the De Vere Tortworth Court, Gloucester.</p>
						<p>Network with like-minded professionals to support your current strategic objectives. Attendance will give you the chance to learn how best to implement, build and buy the right solutions and technologies that are at the forefront of HSE improvement in the UK.</p>
					</div>
				</div>
			</div>
		)
	}
}

export default ShortDescription;
