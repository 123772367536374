import React, { Component } from 'react';
import Banner from './banner';
import UnderBannerRow from './under-banner-row';

import venue1 from './images/venue-1.jpg';
import venue2 from './images/venue-2.jpg';
import venue3 from './images/venue-3.jpg';

class Venue extends Component {
	render() {
		return (
			<div>
				<Banner />
				<UnderBannerRow message='Venue Information' />
				<div className='container'>
					<div className="row" style={{padding: '30px 0px'}}>
						<div className="col-xs-12 col-sm-6" style={{textAlign: 'justify'}}>
							<p>
								<strong>De Vere Tortworth Court</strong>
							</p>
              <p>
                With a history dating back to the 11th-century, when Torteworth Manor was recorded in the Domesday book, the estate at Tortworth has been the ancestral seat of the Ducie family for 350 years and counting.
              </p>
              <p>
                Today, on the north-west edge of the estate, the Grade II* listed De Vere Tortworth Court hotel brings together a wealth of original features along with 21st-century style. Having recently undergone a multi-million pound refurbishment, business, weddings and training events here are anything but usual.
              </p>
              <p>
                Found at the southern fringes of the Cotswolds, rural England's foodie capital, local, fresh produce is sourced from good suppliers and served daily at both restaurants, one housed in a 60-foot high atrium. Heading upstairs, the recent refurbishment has created elegant guest bedrooms and suites to reflect modern British heritage.
              </p>
              <p>
                30 acres of estate grounds with access to an extensive arboretum housing more than 300 plant and tree specimens make a great walk out. All found just 20 minutes from Bristol with easy access to J14 of the M5, 30 minutes to Bristol Parkway station and 40 minutes from Bristol airport.
              </p>
							<br />
							<p>
								<strong>Contact Details</strong>
							</p>
              <p>
                De Vere Tortworth Court, Gloucester<br />Tortworth, Wotton-under-Edge<br />GL12 8HH
              </p>
							<p>
								<strong>Telephone</strong>
							</p>
							<p>
                +44 (1454) 263 000
						  </p>
            </div>
						<div className="col-xs-12 col-sm-6">
							<img src={venue1} alt='venue1' style={styles.venueImg} />
							<img src={venue2} alt='venue3' style={styles.venueImg} />
							<img src={venue3} alt='venue2' style={styles.venueImg} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Venue;

const styles = {
	venueImg: {
		width: '100%',
		marginBottom: 20
	}
}
