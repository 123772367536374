import React, { Component } from 'react';

import banner from './images/hse-banner.jpg';
import logoWhite from './images/logo-white.svg';
import patternYellowBackground from './images/pattern-yellow-background.svg';

import './css/banner.css';

class Banner extends Component {
	constructor() {
		super();
		this.state = {
			background: '',
			messages: [],
			logoWhite: ''
		}
	}

	componentWillMount() {
		//const route = ((this.props.location || {}).pathname || '').split('/')[1];
		const route = 'home';

		this.setState({
			background: 'url(' + ((data[route || 'home'] || {}).img || banner) + ')',
			messages: (data[route || 'home'] || {}).messages || [],
			logoWhite: (data[route || 'home'] || {}).logoWhite || ''
		});
	}

	render() {
		return (
				<div className='row bg-color-charcoal font-montserrat' style={styles.wrapper}>
					<div className='container banner-container' style={{backgroundImage: this.state.background}}>
					{this.state.messages.map(elm => {
						return <div className={elm.classes} key={elm.text}>{elm.text}</div>;
					})}
					{this.state.logoWhite ? <img src={this.state.logoWhite} className='logo-white' alt='' /> : ''}
				</div>
			</div>
		)
	}
}

export default Banner;

const data = {
	home: {
		img: banner,
		messages: [
			{
				classes: 'banner-message1 color-yellow',
				text: 'Control Safety'
			},
			{
				classes: 'banner-message2 color-yellow',
				text: 'Reduce Incidents'
			},
			{
				classes: 'banner-message5 color-yellow',
				text: 'Save Lives'
			},
			{
				classes: 'banner-message3',
				text: '11th & 12th September 2018'
			},
			{
				classes: 'banner-message4',
				text: 'De Vere Tortworth Court, Gloucester'
			}
		],
		logoWhite: logoWhite
	}
}

const styles = {
	wrapper: {
		backgroundImage: 'url(' + patternYellowBackground + ')',
		backgroundSize: 40
	}
}
