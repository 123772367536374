import React, { Component } from 'react';

import './css/headshots.css';

import patternYellowBackground from './images/pattern-yellow-background.svg';
import cube3DYellowIcon from './images/icons/cube-3d-yellow.svg';
import davidMetcalfe from './images/headshots/david-metcalfe.jpg';
import steveMessam from './images/headshots/steve-messam.jpg';
import karlSimons from './images/headshots/karl-simons.jpg';
import kenAnderson from './images/headshots/ken-anderson.jpg';
import duncanSpencer from './images/headshots/duncan-spencer.jpg';
import peterBrown from './images/headshots/peter-brown.jpg';
import iainFerguson from './images/headshots/iain-ferguson.jpg';
import heatherBryant from './images/headshots/heather-bryant.jpg';
import heathRalphson from './images/headshots/heath-ralphson.jpg';
import michaelReilly from './images/headshots/michael-reilly.jpg';
import sarahMitchell from './images/headshots/sarah-mitchell.jpg';
import graemeWaller from './images/headshots/graeme-waller.jpg';
import neilPickering from './images/headshots/neil-pickering.jpg';
import philWalker from './images/headshots/phil-walker.jpg';

class Headshots extends Component {
	render() {
		return (
			<div className='row bg-color-charcoal headshots-container' style={styles.container}>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 text-center color-white font-montserrat headshots-category'>
							<img src={cube3DYellowIcon} style={{width: 50, marginRight: 15}} alt='' />
							Advisory Council
						</div>
						{advisoryCouncil.map((elm, index) => {
							return <Box key={index} data={elm} offset={index === 4 ? true : false} />
						})}
					</div>
					<div className='row' style={{paddingTop: 70}}>
						<div className='col-xs-12 text-center color-white font-montserrat headshots-category'>
							Attendees
						</div>
						{attendees.map((elm, index) => {
							return <Box key={index} data={elm} />
						})}
					</div>
				</div>
			</div>
		)
	}
}

export default Headshots;

function Box(props) {
	let classBox = 'col-xs-6 col-sm-3' + (props.offset ? ' col-sm-offset-3' : '');

	return (
		<div className={classBox} style={{paddingTop: 30}}>
			<div className='headshots-box bg-color-yellow color-charcoal text-center font-montserrat'>
				<img className='filter-grey' src={props.data.image} style={{width: '100%'}} alt='' />
				<p style={{height: 50, marginTop: 10, lineHeight: '17px'}}>
					<strong className='headshots-name'>{props.data.name}</strong>
					<br />
					<span className='headshots-position'>{props.data.position}</span>
					<br />
					<span className='headshots-company'>{props.data.company}</span>
				</p>
			</div>
		</div>
	)
}

const advisoryCouncil = [
	{
		name: 'David Metcalfe',
		position: 'CEO & Co-Founder',
		company: 'Verdantix',
		image: davidMetcalfe
	},
	{
		name: 'Steve Messam',
		position: 'Global Head HSE',
		company: 'Rolls Royce',
		image: steveMessam
	},
	{
		name: 'Karl Simons',
		position: 'Head of HSE',
		company: 'Thames Water',
		image: karlSimons
	},
	{
		name: 'Ken Anderson',
		position: 'Head of HSE',
		company: 'Siemens PLC',
		image: kenAnderson
	},
	{
		name: 'Duncan Spencer',
		position: 'Lead HSE Practitioner',
		company: 'IOSH',
		image: duncanSpencer
	},
	{
		name: 'Peter Brown',
		position: 'Head Programme',
		company: 'The HSE',
		image: peterBrown
	}
]

const attendees = [
	{
		name: 'Iain Ferguson',
		position: 'Head of HSE',
		company: 'Virgin Trains',
		image: iainFerguson
	},
	{
		name: 'Phil Walker',
		position: 'Global HSE Director',
		company: 'Kongsberg',
		image: philWalker
	},
	{
		name: 'Michael Reilly',
		position: 'Director of HSE',
		company: 'WSH Limited',
		image: michaelReilly
	},
	{
		name: 'Sarah Mitchell',
		position: 'Safety Improvement',
		company: 'Heathrow',
		image: sarahMitchell
	},
	{
		name: 'Graeme Waller',
		position: 'HSE Manager',
		company: 'ABB',
		image: graemeWaller
	},
	{
		name: 'Neil Pickering',
		position: 'Group Director HSE',
		company: 'JCB',
		image: neilPickering
	},
	{
		name: 'Heather Bryant',
		position: 'HSE Director',
		company: 'Balfour Beatty PLC',
		image: heatherBryant
	},
	{
		name: 'Heath Ralphson',
		position: 'Group Head of HSE',
		company: 'Aggreko',
		image: heathRalphson
	}
]

const styles = {
	container: {
		backgroundImage: 'url(' + patternYellowBackground + ')',
		backgroundSize: 40,
		padding: '30px 0px'
	}
}
