import React, { Component } from 'react';

import './css/under-banner-row.css';

import ioshLogoCharcoal from './images/iosh-logo-charcoal.svg';
import verdantixLogoCharcoal from './images/verdantix-logo-charcoal.svg';
import thamesWaterLogoCharcoal from './images/thames-water-logo-charcoal.svg';
import rollsRoyceLogoCharcoal from './images/rolls-royce-logo-charcoal.svg';
import theHseLogoCharcoal from './images/the-hse-logo-charcoal.svg';
import siemensLogoCharcoal from './images/siemens-logo-charcoal.svg';

class UnderBannerRow extends Component {
	render() {
		let content = this.props.message ? (
				<div className='row bg-color-yellow color-charcoal font-montserrat' style={styles.wrapperText}>
					{this.props.message}
				</div>
			) : (
				<div className='row bg-color-yellow color-charcoal font-montserrat' style={styles.wrapperPartner}>
					<p className='text-center under-banner-row-partner-message'>
						<strong>Official Advisory Board</strong>
					</p>
					<img className='under-banner-row-partner-img' src={ioshLogoCharcoal} alt=''  />
					<img className='under-banner-row-partner-img' src={verdantixLogoCharcoal} alt='' style={{height: 25}} />
					<img className='under-banner-row-partner-img' src={thamesWaterLogoCharcoal} alt='' />
					<img className='under-banner-row-partner-img' src={rollsRoyceLogoCharcoal} alt='' />
					<img className='under-banner-row-partner-img' src={theHseLogoCharcoal} alt='' />
					<img className='under-banner-row-partner-img' src={siemensLogoCharcoal} alt='' style={{height: 18}} />
				</div>
			);

		return (content);
	}
}

export default UnderBannerRow;

const styles = {
	wrapperText: {
		padding: 20,
		fontSize: '2em',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	wrapperPartner: {
		minHeight: 80,
		textAlign: 'center'
	}
}
