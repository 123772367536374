import React, { Component } from 'react';
import Banner from './banner';
import UnderBannerRow from './under-banner-row';

import ajaxLoader from './images/ajax-loader.gif';
import attendeesIcon from './images/attendees.svg';

class Attendees extends Component {
	constructor() {
		super();
		this.state = {
			attendees: [],
			pwd: ''
		}
	}

	setPwd(val) {
		this.setState({pwd: val}, this.fetchAttendees);
	}

	componentWillMount() {
		let attendeesPwd = window.localStorage.getItem('AttendeesPwd');
		if (attendeesPwd) {
			this.setState({pwd: attendeesPwd}, this.fetchAttendees);
		}
	}

	fetchAttendees() {
		fetch('/api/store/json/attendees', {
			method: 'GET',
			headers: {
				'Authorization': 'Basic ' + btoa('guest:' + this.state.pwd)
			}
		})
		.then(res => res.json())
		.then(attendees => {
			this.setState({
				attendees: attendees
			});
			window.localStorage.setItem('AttendeesPwd', this.state.pwd);
		})
		.catch(err => {
			console.log(err);
			this.setState({pwd: ''});
			window.localStorage.setItem('AttendeesPwd', '');
		});
	}

	render() {
		return (
			<div>
				<Banner />
				<UnderBannerRow />
				<div className='container'>
					{!this.state.pwd ? (
						<Credentials setPwd={this.setPwd.bind(this)} />
					) : (
						!this.state.attendees.length ? (
							<Loader />
						) : (
							<AttendeesList attendees={this.state.attendees} />
						)
					)}
				</div>
			</div>
		);
	}
}

export default Attendees;

class Row extends Component {
	render() {
		return (
			<div className={this.props.dataClassName} style={{paddingBottom: 13, overflow: 'auto', display: 'block'}}>
				<div className='col-xs-12 col-sm-offset-1 col-sm-3'>
					<strong>{this.props.data.company}</strong>
				</div>
				<div className='col-xs-12 col-sm-4'>{this.props.data.position}</div>
				<div className='col-xs-12 col-sm-4'>{this.props.data.name}</div>
			</div>
		)
	}
}

class Credentials extends Component {
	constructor() {
		super();
		this.state = {
			pwd: ''
		}
	}

	onChange(e) {
		this.setState({pwd: e.target.value});
	}

	setPwd(e) {
		e.preventDefault();
		this.props.setPwd(this.state.pwd);
	}

	render() {
		return (
			<div className='row' style={{padding: '30px 0px', textAlign: 'center'}}>
				<div className='col-xs-12 col-sm-offset-4 col-sm-4'>
					<form onSubmit={e => this.setPwd(e)}>
					<div className='input-group'>
						<input
							type='password'
							className='form-control bg-color-grey color-white'
							value={this.state.pwd}
							onChange={this.onChange.bind(this)}
						/>
						<span className='input-group-btn'>
							<button type='submit' className='btn bg-color-yellow'>
								<strong>OK</strong>
							</button>
						</span>
					</div>
					</form>
				</div>
			</div>
		)
	}
}

function Loader() {
	return (
		<div className='row' style={{padding: '30px 0px', textAlign: 'center'}}>
			<div className='col-xs-12'>
				<img src={ajaxLoader} alt='' />
			</div>
		</div>
	);
}

function AttendeesList(props) {
	return (
		<div>
			<div style={{textAlign: 'center', padding: '30px 0px'}}>
				<p className="color-dark-grey" style={{fontSize: '1.5em', margin: 0}}>
					<strong>ATTENDEES</strong>
				</p>
				<img src={attendeesIcon} style={{width: 50}} alt='' />
			</div>
			<div className='row' style={{paddingBottom: 30}}>
				{props.attendees.map((elm, index) => {
					return <Row data={elm} dataClassName={index % 2 === 0 ? 'attendees-align color-charcoal' : 'attendees-align color-grey'} />
				})}
			</div>
		</div>
	);
}
