import React, { Component } from 'react';
import Banner from './banner';
import UnderBannerRow from './under-banner-row';

import hseLogoWhite from './images/hse-logo-white.svg';
import arcoLogoCharcoal from './images/arco-logo-charcoal.svg';
import sapLogoCharcoal from './images/sap-logo-charcoal.svg';
import ulLogoCharcoal from './images/ul-logo-charcoal.svg';
import dekraLogoVerticalCharcoal from './images/dekra-logo-vertical-charcoal.svg';
import saiGlobalLogoCharcoal from './images/sai-global-logo-charcoal.svg';
import animmersionLogoCharcoal from './images/animmersion-logo-charcoal.svg';
import progarmLogoCharcoal from './images/progarm-logo-charcoal.svg';
import dragerLogoCharcoal from './images/drager-logo-charcoal.svg';
import centurionLogoCharcoal from './images/centurion-logo-charcoal.svg';
import ringersLogoCharcoal from './images/ringers-logo-charcoal.svg';
import staySafeLogoCharcoal from './images/stay-safe-logo-charcoal.svg';
import scannellLogoCharcoal from './images/scannell-logo-charcoal.svg';
import groundworkLogoCharcoal from './images/groundwork-logo-charcoal.svg';
import airswebLogoCharcoal from './images/airsweb-logo-charcoal.svg';
import enablonLogoCharcoal from './images/enablon-logo-charcoal.svg';
import gfgLogoCharcoal from './images/gfg-logo-charcoal.svg';
import gensuiteLogoCharcoal from './images/gensuite-logo-charcoal.svg';
import thamesWaterLogoCharcoal from './images/thames-water-logo-charcoal.svg';
import abbLogoCharcoal from './images/abb-logo-charcoal.svg';
import siemensLogoCharcoal from './images/siemens-logo-charcoal.svg';
import virginTrainsLogoCharcoal from './images/virgin-trains-logo-charcoal.svg';
import integralLogoCharcoal from './images/integral-logo-charcoal.svg';
import jcbLogoCharcoal from './images/jcb-logo-charcoal.svg';
import rollsRoyceLogoCharcoal from './images/rolls-royce-logo-charcoal.svg';
import kongsbergLogoCharcoal from './images/kongsberg-logo-charcoal.svg';
import heathrowLogoCharcoal from './images/heathrow-logo-charcoal.svg';
import theAaLogoCharcoal from './images/the-aa-logo-charcoal.svg';
import centricaLogoCharcoal from './images/centrica-logo-charcoal.svg';
import alcatelLucentLogoCharcoal from './images/alcatel-lucent-logo-charcoal.svg';
import cbiLogoCharcoal from './images/cbi-logo-charcoal.svg';
import interserveLogoCharcoal from './images/interserve-logo-charcoal.svg';
import maceLogoCharcoal from './images/mace-logo-charcoal.svg';
import wincantonLogoCharcoal from './images/wincanton-logo-charcoal.svg';
import arrivaLogoCharcoal from './images/arriva-logo-charcoal.svg';
import certasEnergyLogoCharcoal from './images/certas-energy-logo-charcoal.svg';
import creditSuisseLogoCharcoal from './images/credit-suisse-logo-charcoal.svg';
import essentraLogoCharcoal from './images/essentra-logo-charcoal.svg';
import highwaysEnglandLogoCharcoal from './images/highways-england-logo-charcoal.svg';
import homeGroupLogoCharcoal from './images/home-group-logo-charcoal.svg';
import healthAndSafetyExecutiveLogoCharcoal from './images/health-and-safety-executive-logo-charcoal.svg';
import isgLogoCharcoal from './images/isg-logo-charcoal.svg';
import itvLogoCharcoal from './images/itv-logo-charcoal.svg';
import ladbrokesCoralLogoCharcoal from './images/ladbrokes-coral-logo-charcoal.svg';
import mitieLogoCharcoal from './images/mitie-logo-charcoal.svg';
import mullerLogoCharcoal from './images/muller-logo-charcoal.svg';
import portakabinLogoCharcoal from './images/portakabin-logo-charcoal.svg';
import saintGobainLogoCharcoal from './images/saint-gobain-logo-charcoal.svg';
import stobartGroupLogoCharcoal from './images/stobart-group-logo-charcoal.svg';
import thomasCookLogoCharcoal from './images/thomas-cook-logo-charcoal.svg';
import toyotaLogoCharcoal from './images/toyota-logo-charcoal.svg';
import tullowOilLogoCharcoal from './images/tullow-oil-logo-charcoal.svg';
import baxterStoreyLogoCharcoal from './images/baxter-storey-logo-charcoal.svg';

class Sponsors extends Component {
	render() {
		return (
			<div>
				<Banner />
				<UnderBannerRow />
				<div className='row bg-color-charcoal font-montserrat' style={{padding: '50px 0px', textAlign: 'center', color: '#fff'}}>
					<img src={hseLogoWhite} style={{height: 100}} alt='' />
					<br />
					<div style={{margin: '10px 0px'}}>
						<div style={{display: 'inline', padding: '0px 10px', border: '1px solid #ffcc33', color: '#fff', fontWeight: 'bold', fontSize: '4em'}}>2018</div>
					</div>
					<div style={{fontSize: '1.5em'}}>7th and 8th February</div>
					<div>Four Seasons Hotel, Hampshire</div>
				</div>
				<div className='container'>
					<div className='row' style={{paddingTop: 50}}>
						<div className='col-xs-12' style={{textAlign: 'center'}}>
							<div style={styles.rowTitles}>
								Sponsors
								<hr style={styles.hrYellowFade} />
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.arco.co.uk' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={arcoLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.sap.com' target='_blank' rel='noopener noreferrer'>
								<img style={styles.logoCharcoal} src={sapLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.ul.com' target='_blank' rel='noopener noreferrer'>
								<img style={styles.logoCharcoal} src={ulLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.dekra.com/en-us/home/' target='_blank' rel='noopener noreferrer'>
								<img style={styles.logoCharcoal} src={dekraLogoVerticalCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.saiglobal.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={saiGlobalLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='http://www.animmersion.co.uk' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={animmersionLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.progarm.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 17}}} src={progarmLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.draeger.com/en_uk/Home' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={dragerLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://centurionsafety.eu' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={centurionLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='http://ringersgloves.com' target='_blank' rel='noopener noreferrer'>
								<img style={styles.logoCharcoal} src={ringersLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.staysafeapp.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={staySafeLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='http://www.scannellsolutions.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={scannellLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='http://www.groundworksafety.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={groundworkLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.airsweb.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 13}}} src={airswebLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://enablon.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={enablonLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='http://www.gfg-inc.com/englisch/start.html' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={gfgLogoCharcoal} alt='' />
							</a>
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<a href='https://www.gensuite.com' target='_blank' rel='noopener noreferrer'>
								<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={gensuiteLogoCharcoal} alt='' />
							</a>
						</div>
					</div>
					<div className='row' style={{position: 'relative', top: -20, paddingBottom: 50}}>
						<div className='col-xs-12'>
							<hr style={styles.hrYellowFade} />
						</div>
					</div>
					<div className='row'>
						<div className='col-xs-12' style={{textAlign: 'center'}}>
							<div style={styles.rowTitles}>
								Executive Attendees From
								<hr style={styles.hrYellowFade} />
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={thamesWaterLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={abbLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={siemensLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={virginTrainsLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={integralLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={jcbLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={rollsRoyceLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 23}}} src={kongsbergLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={heathrowLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={theAaLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={centricaLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={alcatelLucentLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={cbiLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={interserveLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={maceLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={wincantonLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={arrivaLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={certasEnergyLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 17}}} src={creditSuisseLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={essentraLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 15}}} src={highwaysEnglandLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={homeGroupLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={healthAndSafetyExecutiveLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={isgLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={itvLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 10}}} src={ladbrokesCoralLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 12}}} src={mitieLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={mullerLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={portakabinLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 5}}} src={saintGobainLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 25}}} src={stobartGroupLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={thomasCookLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={toyotaLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 0}}} src={tullowOilLogoCharcoal} alt='' />
						</div>
						<div className='col-xs-6 col-sm-4 col-md-3 col-lg-2' style={styles.col}>
							<img style={{...styles.logoCharcoal, ...{marginTop: 20}}} src={baxterStoreyLogoCharcoal} alt='' />
						</div>
					</div>
					<div className='row' style={{position: 'relative', top: -20, paddingBottom: 50}}>
						<div className='col-xs-12'>
							<hr style={styles.hrYellowFade} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Sponsors;

const styles = {
	rowTitles: {
		display: 'inline-block',
		fontWeight: 'bold',
		fontSize: '2em'
	},
	hrYellowFade: {
		margin: '5px 0px',
		border: 0,
		height: 2,
		backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 204, 51, 0.75), rgba(255, 204, 51, 0.75), rgba(255, 204, 51, 0.75), rgba(0, 0, 0, 0))'
	},
	col: {
		height: 100,
		marginBottom: 20,
		textAlign: 'center'
	},
	logoCharcoal: {
		maxWidth: '100%',
		maxHeight: 100,
		padding: 15
	}
}
