import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Home from './home';
import Venue from './venue';
import Sponsors from './sponsors';
import Register from './register';
import Contact from './contact';
import Attendees from './attendees';
import Footer from './footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/app.css';

import logo from './images/logo.svg';

class App extends Component {
	render() {
		return (
			<Router>
				<div className='container-fluid'>
					<Navbar collapseOnSelect style={{backgroundColor: '#fff', border: 0, borderRadius: 0, marginBottom: 0}}>
						<Navbar.Header>
							<Navbar.Brand>
								<a href='/' className='logo' style={{padding: 0, height: 100}}>
									<img src={logo} alt='logo' style={{height: 80, marginTop: 10}} />
								</a>
							</Navbar.Brand>
							<Navbar.Toggle style={{marginTop: 30}} />
						</Navbar.Header>
						<Navbar.Collapse>
							<Nav pullRight className='nav-collapse'>
								<LinkContainer exact to='/'>
									<NavItem eventKey={1}>
										<div>Home</div>
									</NavItem>
								</LinkContainer>
								<LinkContainer to='/attendees'>
									<NavItem eventKey={2}>
										<div>Attendees</div>
									</NavItem>
								</LinkContainer>
								<LinkContainer to='/venue'>
									<NavItem eventKey={3}>
										<div>Venue</div>
									</NavItem>
								</LinkContainer>
								<LinkContainer to='/sponsors'>
									<NavItem eventKey={4}>
										<div>Sponsors</div>
									</NavItem>
								</LinkContainer>
								<LinkContainer to='/register'>
									<NavItem eventKey={5}>
										<div>Register</div>
									</NavItem>
								</LinkContainer>
								<LinkContainer to='/contact'>
									<NavItem eventKey={6}>
										<div>Contact</div>
									</NavItem>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
					<Route exact path='/' component={Home} />
					<Route path='/attendees' component={Attendees} />
					<Route path='/venue' component={Venue} />
					<Route path='/sponsors' component={Sponsors} />
					<Route path='/register' component={Register} />
					<Route path='/contact' component={Contact} />
					<Footer />
				</div>
			</Router>
		);
	}
}

export default App;
