import React, { Component } from 'react';
import Banner from './banner';
import UnderBannerRow from './under-banner-row';
import ShortDescription from './short-description';
import ShortInfo from './short-info';
import Schedule from './schedule';
import Headshots from './headshots';
import VenueInfo from './venue-info';

class Home extends Component {
	render() {
		return (
			<div>
				<Banner />
				<UnderBannerRow />
				<ShortDescription />
				<ShortInfo />
				<Schedule />
				<Headshots />
				<VenueInfo />
			</div>
		)
	}
}

export default Home;
