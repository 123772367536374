import React, { Component } from 'react';

import delegates from './images/attendees.svg';
import microphone from './images/microphone.svg';
import calendar from './images/calendar.svg';
import sponsors from './images/sponsors.svg';

const info = [
	{
		value: 60,
		text: 'Attendees',
		icon: delegates
	},
	{
		value: 14,
		text: 'Speakers',
		icon: microphone
	},
	{
		value: 22,
		text: 'Sessions',
		icon: calendar
	},
	{
		value: 20,
		text: 'Solution Providers',
		icon: sponsors
	}
]

class ShortInfo extends Component {
	render () {
		return (
			<div className="row bg-color-charcoal" style={styles.wrapper}>
				<div className='container'>
					{info.map((elm) => {
						return <Box data={elm} key={elm.text} />
					})}
				</div>
			</div>
		)
	}
}

export default ShortInfo;

class Box extends Component {
	render () {
		return (
			<div className="col-xs-6 col-sm-3" style={{padding: '30px 0px', textAlign: 'center', color: '#fff'}}>
				<p style={{fontSize: '2em', fontWeight: 'bold'}}>{this.props.data.value}</p>
				<p style={{fontWeight: 'bold'}}>{this.props.data.text}</p>
				<p>
					<img src={this.props.data.icon} style={{width: 50}} alt={this.props.data.text} />
				</p>
			</div>
		)
	}
}

const styles = {
	wrapper: {
		padding: '30px 0px'
	}
}
