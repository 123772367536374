import React, { Component } from 'react';

import './css/footer.css';

import pccLogoBlack from './images/pcc-logo-black.svg';
import linkedinLogoCharcoal from './images/linkedin-logo-charcoal.svg';
import twitterLogoCharcoal from './images/twitter-logo-charcoal.svg';
import facebookLogoCharcoal from './images/facebook-logo-charcoal.svg';
import googlePlusLogoCharcoal from './images/google-plus-logo-charcoal.svg';
import patternBackground from './images/pattern-background.svg';
import gradientBackground from './images/gradient-background.svg';

class Footer extends Component {
	render() {
		return (
			<div className='row bg-color-yellow' style={styles.wrapper}>
				<div className='col-xs-12 col-md-6 footer-pcc-logo-wrapper'>
					<img src={pccLogoBlack} alt='' />
				</div>
				<div className='col-xs-12 col-md-6 footer-message-wrapper'>
					<span className='color-charcoal' style={{fontSize: '1.35em', fontWeight: 'bold'}}>
						Supporting growth.
						<br />
						Engaging audiences.
						<br />
						Recognising talent.
					</span>
				</div>
				<div className='col-xs-12' style={styles.socialMediaWrapper}>
					<a href='https://www.linkedin.com/company/paul-clark-consulting/' target='_blank' rel='noopener noreferrer'>
						<img style={styles.socialMediaIcon} src={linkedinLogoCharcoal} alt='' />
					</a>
					<a href='https://twitter.com/paulclarkcons' target='_blank' rel='noopener noreferrer'>
						<img style={styles.socialMediaIcon} src={twitterLogoCharcoal} alt='' />
					</a>
					<a href='https://www.facebook.com/pg/Paul-Clark-Consulting-153436978698054' target='_blank' rel='noopener noreferrer'>
						<img style={styles.socialMediaIcon} src={facebookLogoCharcoal} alt='' />
					</a>
					<img style={styles.socialMediaIcon} src={googlePlusLogoCharcoal} alt='' />
				</div>
				<div className='col-xs-12' style={{textAlign: 'center'}}>
					<span style={styles.text}>
						HSE UK Congress is a product of Paul Clark Sales Consulting Limited, a registered company in England and Wales, company number 10680877. Registered Office: 16 Market Street, Bradford on Avon, Wiltshire, BA15 1LL
					</span>
				</div>
			</div>
		);
	}
}

export default Footer;

const styles = {
	wrapper: {
		paddingTop: 40,
		backgroundImage: 'url(' + gradientBackground + '), url(' + patternBackground + ')',
		backgroundSize: '1000px, 40px',
		backgroundRepeat: 'no-repeat, repeat',
		backgroundPosition: 'center'
	},
	socialMediaWrapper: {
		paddingTop: 40,
		paddingBottom: 20,
		textAlign: 'center'
	},
	socialMediaIcon: {
		height: 48,
		margin: '0px 10px 0px 10px'
	},
	text: {
		fontSize: '0.65em'
	}
}
