import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import calendar from './images/calendar.svg';

import 'react-tabs/style/react-tabs.css';
import './css/schedule.css';

class Schedule extends Component {
	render () {
		return (
			<div className='container'>
				<div className='row' style={{padding: '30px 0px'}}>
					<div style={{textAlign: 'center', paddingBottom: 20}}>
						<img src={calendar} style={{width: 50}} alt="calendar" />
						<p className='color-dark-grey' style={{fontSize: '1.5em', margin: 0}}>
							<strong>SCHEDULE</strong>
						</p>
						<p className="color-grey" style={{margin: 0}}>TUESDAY 11<sup>th</sup> & WEDNESDAY 12<sup>th</sup> SEPTEMBER 2018</p>
					</div>
					<Tabs>
						<TabList style={{textAlign: 'center'}}>
							<Tab>Day 1</Tab>
							<Tab>Day 2</Tab>
						</TabList>
						<br />
						<TabPanel>
							<Day morning={data.day1.morning} afternoon={data.day1.afternoon} evening={data.day1.evening} />
						</TabPanel>
						<TabPanel>
							<Day morning={data.day2.morning} afternoon={data.day2.afternoon} evening={data.day2.evening} />
						</TabPanel>
					</Tabs>
				</div>
			</div>
		)
	}
}

export default Schedule;

function Day(props) {
	return (
		<Tabs className="daytab">
			<TabList style={{textAlign: 'center'}}>
				{props.morning.length ? <Tab>Morning</Tab> : ''}
				{props.afternoon ? <Tab>Afternoon</Tab> : ''}
				{props.evening.length ? <Tab>Evening</Tab> : ''}
			</TabList>
			<br />
			{props.morning.length ? (
				<TabPanel className="col-xs-12 col-sm-offset-1 col-sm-10 color-grey">
					{props.morning.map(elm => {
						return <Row data={elm} key={elm.hours} />
					})}
				</TabPanel>
			) : ''}
			{props.afternoon.length ? (
				<TabPanel className="col-xs-12 col-sm-offset-1 col-sm-10 color-grey">
					{props.afternoon.map(elm => {
						return <Row data={elm} key={elm.hours} />
					})}
				</TabPanel>
			) : ''}
			{props.evening.length ? (
				<TabPanel className="col-xs-12 col-sm-offset-1 col-sm-10 color-grey">
					{props.evening.map(elm => {
						return <Row data={elm} key={elm.hours} />
					})}
				</TabPanel>
			): ''}
		</Tabs>
	);
}

class Row extends Component {
	render() {
		return (
			<div style={{borderTop: '1px solid #ddd', padding: '10px 0px 5px 0px', overflow: 'auto'}}>
				<div className="col-xs-12 col-sm-4">{this.props.data.hours}</div>
				<div className="col-xs-12 col-sm-8">
					<strong className="color-dark-grey" dangerouslySetInnerHTML={{__html: this.props.data.title}}></strong>
					<br />
					<span style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: this.props.data.desc}}></span>
				</div>
			</div>
		)
	}
}
const data = {
	day1: {
		morning: [
			{
				hours: '07:00 - 08:30',
				title: 'Registration and Welcome',
				desc: ''
			},
			{
				hours: '08:30 - 09:30',
				title: 'Breakfast Panel',
				desc: 'Digital Transformation in EHS - Where are you currently and how has it been affecting your business now? Which technologies are in the market to roll out key projects effectively?'
			},
			{
				hours: '09:30 - 10:30',
				title: 'Roundtables',
				desc: '1, 2, 3'
			},
			{
				hours: '10:30 - 10:50',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '10:50 - 11:30',
				title: 'Workshop 1',
				desc: 'The growing use of mobile apps and how will this help you achieve your EHS management goals?'
			},
			{
				hours: '11:30 - 11:50',
				title: 'Networking',
				desc: ''
			},
			{
				hours: '11:50 - 12:30',
				title: 'Workshop 2',
				desc: 'Turning Data Into Actionable Insight - There are many tools at our disposal as EHS leaders, but one of the hardest things is making sense of the data and managing that data for better incident reporting and insights to improve safety.'
			}
		],
		afternoon: [
			{
				hours: '12:30 - 13:30',
				title: 'Working Buffet Lunch & Networking',
				desc: ''
			},
			{
				hours: '13:30 - 14:10',
				title: 'Workshop 3',
				desc: 'Recruitment in HSE - How do we source the right talent and gain access to the right professionals?'
			},
			{
				hours: '14:10 - 14:30',
				title: 'Networking',
				desc: ''
			},
			{
				hours: '14:30 - 15:10',
				title: 'Workshop 4',
				desc: 'Telematics - The future of telematics and how it will improve safety'
			},
			{
				hours: '15:10 - 15:30',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '15:30 - 16:10',
				title: 'Workshop 5',
				desc: 'Safety Leadership is NOT safety management - how do we change the culture for leaders to realise that safety is not just a top-down approach, but is installing behaviours across all levels of management and not just a job role responsibility? What are we missing? What theories and behaviours can improve?'
			},
			{
				hours: '16:10 - 16:30',
				title: 'Networking',
				desc: ''
			},
			{
				hours: '16:30 - 17:10',
				title: 'Workshop 6',
				desc: 'Health & Wellbeing Integration into Safety - Employee health and wellbeing is not just an emerging topic in the EHS field, but one that is quickly gaining momentum. Research, medical, and academic groups are pioneering the concept of integrated health and safety, which is the blending of health and safety programs along a continuum of organizational, personal, and occupational activities to enhance overall worker wellbeing and prevent work-related injuries and illnesses.'
			},
			{
				hours: '17:10 - 18:10',
				title: 'Coffee & Networking',
				desc: ''
			}
		],
		evening: [
			{
				hours: '18:10 - 19:15',
				title: 'Cocktail Reception (out on the lawn) (live music)',
				desc: ''
			},
			{
				hours: '19:15 - 21:00',
				title: 'Gala Dinner - Keynote Speaker',
				desc: ''
			},
			{
				hours: '21:00 - 22:00',
				title: 'Open Bar and Goodnight',
				desc: ''
			}
		]
	},
	day2: {
		morning: [
			{
				hours: '08:30 - 09:30',
				title: 'Breakfast Panel',
				desc: 'The Zero Harm ambition - is it time for a change? Best practices to achieve Zero Harm in your business'
			},
			{
				hours: '09:30 - 10:30',
				title: 'Roundtables',
				desc: '1, 2, 3'
			},
			{
				hours: '10:30 - 10:50',
				title: 'Coffee & Networking',
				desc: ''
			},
			{
				hours: '10:50 - 11:30',
				title: 'Workshop 7',
				desc: 'HSE Training - Improving the ability to influence your workforce and what strategies can you use to get better employee engagement. Invest into their success'
			},
			{
				hours: '11:30 - 11:50',
				title: 'Networking',
				desc: ''
			},
			{
				hours: '11:50 - 12:30 ',
				title: 'Workshop 8',
				desc: 'Sleepless Night - Fire Safety, Protection and Detection. What have we learned in the past 12 months about the importance of fire safety?'
			}
		],
		afternoon: [
			{
				hours: '12:30 - 13:30',
				title: 'Working Buffet Lunch & Networking',
				desc: ''
			},
			{
				hours: '13:30 - 14:10',
				title: 'Workshop 9',
				desc: 'EHS Roadmap - How to improve EHS with a technologies roadmap; analyst partner - Verdantix'
			},
			{
				hours: '14:10 - 14:30',
				title: 'Networking',
				desc: ''
			},
			{
				hours: '14:30 - 15:10',
				title: 'Workshop 10',
				desc: 'Virtual Reality - an exciting virtual future. The future looks bright for HSE with new virtual tools being deployed which can help improve safety and culture in the workplace. How do they work and how can they help?'
			},
			{
				hours: '15:10 - 15:30',
				title: 'Coffee Networking',
				desc: ''
			},
			{
				hours: '15:30 - 16:10',
				title: 'Workshop 11',
				desc: 'Process Safety - we will look at emergency response, safety tracking and LOTO. Planning for emergencies cannot be considered reliable until it is exercised and has been proven to be workable, especially since false confidence may be placed in the integrity of a written plan. How do we make it easier for ourselves to manage risks, identify hazards, and prevent accidents?'
			},
			{
				hours: '16:10',
				title: 'Final Networking, Thank You and Goodbye - Paul Clark, CEO - Paul Clark Consulting',
				desc: ''
			}
		],
		evening: []
	}
}
