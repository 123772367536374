import React, { Component } from 'react';
import Banner from './banner';
import UnderBannerRow from './under-banner-row';

import teamImage from './images/team.jpg';
import locationSvg from './images/location.svg';
import mailSvg from './images/mail.svg';
import phoneSvg from './images/phone.svg';
import linkedinSvg from './images/linkedin.svg';

class Contact extends Component {
	render() {
		return (
			<div>
				<Banner />
				<UnderBannerRow message='Contact us' />
				<div className='container'>
					<div className='row' style={{paddingTop: 30}}>
						<div className='col-xs-12 col-sm-6'>
							{data.map(elm => {
								let text = elm.link ? <a href={elm.link} target='_blank' rel="noopener noreferrer" style={{overflowWrap: 'break-word'}}>{elm.text}</a> : elm.text;

								return (
									<div style={{marginBottom: 35}} key={elm.title}>
										<div style={{float: 'left'}}>
											<img src={elm.icon} style={{width: 40}} alt={elm.title} />
										</div>
										<div style={{marginLeft: 65}}>
											<div className='color-grey font-montserrat' style={{fontSize: '1.3em', marginBottom: 10}}>
												<strong>{elm.title}</strong>
											</div>
											<div>{text}</div>
										</div>
									</div>
								)
							})}
						</div>
						<div className='col-xs-12 col-sm-6'>
							<h3 className='font-montserrat' style={{marginTop: 0, textAlign: 'center'}}>Management Team</h3>
							<img src={teamImage} style={{width: '100%'}} alt='Management Team' />
							<span className='color-grey' style={{fontSize: '0.8em'}}>Left to Right:</span>
							<br />
							<span style={{fontSize: '0.9em', textAlign: 'justify'}}>Jason Hill - Finance & Operations Director, Brad King-Taylor - Sales Director, Paul Clark - CEO, Stefan Rascanu - IT Director, Katie Clark - Event Director</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;

const data = [
	{
		icon: locationSvg,
		title: 'Office',
		text: '16 Market Street, Bradford on Avon, Wiltshire, BA15 1LL'
	},
	{
		icon: mailSvg,
		title: 'Email',
		text: 'info@paulclarkconsulting.co.uk'
	},
	{
		icon: phoneSvg,
		title: 'Phone',
		text: '01225 899061'
	},
	{
		icon: linkedinSvg,
		title: 'LinkedIn',
		text: 'https://www.linkedin.com/company/11058413',
		link: 'https://www.linkedin.com/company/11058413'
	}
]
